import { render, staticRenderFns } from "./Imprint.vue?vue&type=template&id=38132b5a&scoped=true&"
import script from "./Imprint.vue?vue&type=script&lang=js&"
export * from "./Imprint.vue?vue&type=script&lang=js&"
import style0 from "./Imprint.vue?vue&type=style&index=0&id=38132b5a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38132b5a",
  null
  
)

/* custom blocks */
import block0 from "@/locales/pages/imprint.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CBibliotheken%5CDocuments%5CDennis-Adamczyk%5CWebsite%20-%20dennis-adamczyk.de%5Cv3%5Cdennis-adamczyk%5Csrc%5Cpages%5CImprint.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports