<i18n src="@/locales/pages/imprint.json"></i18n>

<template>
  <div>
    <section id="intro">
      <div class="content">
        <div class="dot-pattern rellax"></div>
        <div class="title">
          <h1>
            {{ $t('imprint.title') }}
          </h1>
          <p class="subtitle">
            {{ $t('imprint.intro.subtitle') }}
          </p>
        </div>
        <p class="address">
          {{ $t('imprint.intro.address') }}
        </p>
        <article class="representative">
          <p class="heading">
            {{ $t('imprint.intro.representative.heading') }}
          </p>
          <p class="text">
            {{ $t('imprint.intro.representative.text') }}
          </p>
        </article>
        <article class="contact">
          <p class="heading">
            {{ $t('imprint.intro.contact.heading') }}
          </p>
          <table>
            <tr class="phone">
              <th>{{ $t('imprint.intro.contact.phone.heading') }}</th>
              <td>
                <a href="tel:+4917643811166">
                  {{ $t('imprint.intro.contact.phone.text') }}
                </a>
              </td>
            </tr>
            <tr class="email">
              <th>{{ $t('imprint.intro.contact.email.heading') }}</th>
              <td>
                <a href="mailto:ich@dennis-adamczyk.de">
                  {{ $t('imprint.intro.contact.email.text') }}
                </a>
              </td>
            </tr>
          </table>
        </article>
      </div>
    </section>
    <section id="disclaimer">
      <div class="content">
        <h2>
          {{ $t('imprint.disclaimer.heading') }}
        </h2>
        <article v-for="(block, i) in $t('imprint.disclaimer.blocks')" :key="i">
          <h3>
            {{ block.heading }}
          </h3>
          <p v-if="typeof block.text == 'string'">
            {{ block.text }}
          </p>
          <p v-else v-for="(text, j) in block.text" :key="j">
            {{ text }}
          </p>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import Rellax from 'rellax';

export default {
  name: 'Imprint',
  metaInfo() {
    return {
      title: 'Impressum ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'Impressum – Dennis Adamczyk • Web Developer & UI/UX Designer – Erhalte rechtliche Hinweise zu mir als Betreiber meiner Webseite und sozialen Netzwerke.',
        },
        {
          name: 'keywords',
          content: 'dennis adamczyk, impressum, rechtliches, informationen',
        },
      ],
    };
  },
  mounted() {
    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
  },
};
</script>

<style lang="scss" scoped>
#intro {
  overflow: hidden;
  padding: 128px 0 96px 0;

  @media all and ($mobile) {
    padding: 96px 0 64px 0;
  }

  .content {
    display: block;
    max-width: $max-view-width;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;

    .dot-pattern {
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      &::before {
        content: '';
        display: block;
        width: 260px;
        height: 120px;
        transform: translate(60%, -40%);
        z-index: 0;

        @include circle-pattern(0.2);

        @media all and ($mobile) {
          transform: translate(80%, -75%);
        }
      }
    }

    .title {
      margin-bottom: 48px;
      line-height: normal;

      @media all and ($mobile) {
        margin-bottom: 32px;
      }

      h1 {
        font-size: 3.375rem;
        font-weight: 700;
        text-align: left;
        color: $foreground;
        margin: 0 0 8px 0;

        @media all and ($mobile) {
          font-size: 2rem;
          margin: 0 0 4px 0;
        }
      }

      .subtitle {
        font-size: 1.5rem;
        font-weight: 400;
        text-align: left;
        color: $text-color;

        @media all and ($mobile) {
          font-size: 1.125rem;
        }
      }
    }

    p {
      white-space: pre-line;
      line-height: normal;
    }

    .address {
      margin-bottom: 24px;
    }

    .representative {
      margin-bottom: 24px;

      .heading {
        font-weight: 700;
        color: $foreground;
      }
    }

    .contact {
      .heading {
        font-weight: 700;
        color: $foreground;
      }

      table {
        border-collapse: seperate;
        border-spacing: 0;

        tr {
          th {
            font-weight: 600;
            line-height: normal;
            text-align: left;
            vertical-align: baseline;
            padding-right: 16px;
          }

          td {
            line-height: normal;

            a {
              text-decoration: none;
              color: $primary;
              outline: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

#disclaimer {
  margin-bottom: 128px;

  .content {
    display: block;
    max-width: $max-view-width;
    margin: 0 auto;
    padding: 0 24px;

    h2 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.15em;
      color: $foreground;
      margin: 0 0 32px 0;

      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 2px;
        background-color: $primary;
        margin: 0 8px 0 0;
      }
    }

    article {
      width: 100%;
      height: auto;
      margin: 32px 0 48px 0;

      h3 {
        font-size: 2rem;
        font-weight: 700;
        color: $foreground;
        text-align: left;
        margin: 0 0 48px 0;
      }

      p {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        color: $text-color;
        margin: 0 0 24px 0;
      }
    }
  }
}
</style>
